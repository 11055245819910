import React from 'react';
import { PrinterIcon } from '@heroicons/react/24/outline';
import { Listing } from '../../components/property/property-card/PropertyCardTypes';
import { capitalizeWords } from '../../utility/capitalizeWords';
import { formatAvailableDate } from '../../utility/date-formatter';

interface PrintButtonProps {
  property: Listing;
}

const PrintButton: React.FC<PrintButtonProps> = ({ property }) => {
  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    if (!printWindow) return;

    const formatPrice = (price: number) => {
      return new Intl.NumberFormat('en-IE', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0
      }).format(price);
    };

    // Add disclaimer styles to the existing styles
    const extraStyles = property.isExternalListing
      ? `
      .disclaimer {
        background-color: #fff3cd;
        border: 1px solid #ffeeba;
        color: #856404;
        padding: 15px;
        margin-bottom: 20px;
        border-radius: 4px;
        font-size: 14px;
      }
      .disclaimer-title {
        font-weight: bold;
        margin-bottom: 8px;
        color: #856404;
      }
      .warning-box {
        background-color: #fff3cd;
        border-left: 4px solid #ffc107;
        padding: 15px;
        margin-top: 10px;
      }
    `
      : '';

    // Determine contact section content based on isExternalListing
    const contactSection = property.isExternalListing
      ? `
          <div class="section">
            <div class="disclaimer">
              <div class="disclaimer-title">⚠️ External Listing Notice</div>
              <p>This property listing originates from an external source. RentSmart.ie does not verify or guarantee:</p>
              <ul style="margin: 10px 0 10px 20px;">
                <li>The accuracy of the property details</li>
                <li>The availability of the property</li>
                <li>The legitimacy of the listing</li>
                <li>The identity of the advertiser</li>
              </ul>
              <div class="warning-box">
                <strong>Safety Tips:</strong>
                <ul style="margin: 8px 0 0 20px;">
                  <li>Never send money or personal information before viewing a property</li>
                  <li>Always view the property in person before making any payments</li>
                  <li>Be cautious of unrealistically low prices</li>
                  <li>Report suspicious listings to the original platform</li>
                </ul>
              </div>
            </div>
            <div class="section-title">Contact Information</div>
            <div class="detail">
              <p>This is an external listing. Please visit the original listing for contact details:</p>
              <p><a href="${property.externalListingUrl}" target="_blank" style="color: #2563eb; text-decoration: underline;">View Original Listing</a></p>
            </div>
          </div>
        `
      : `
          <div class="section">
            <div class="section-title">Contact Information</div>
            <div class="detail">
              <p><span class="detail-label">Agent:</span> ${property.user.name}</p>
              <p><span class="detail-label">Email:</span> ${property.user.email}</p>
              <p><span class="detail-label">Phone:</span> ${property.user.phone || 'Not provided'}</p>
            </div>
          </div>
        `;

    const printContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>Property Details - ${property.title}</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              line-height: 1.6;
              color: #333;
              max-width: 800px;
              margin: 0 auto;
              padding: 20px;
            }
            .header {
              text-align: center;
              margin-bottom: 30px;
              border-bottom: 2px solid #2563eb;
              padding-bottom: 20px;
            }
            .property-image {
              width: 100%;
              max-height: 400px;
              object-fit: cover;
              margin-bottom: 20px;
            }
            .section {
              margin-bottom: 25px;
            }
            .section-title {
              font-size: 18px;
              font-weight: bold;
              color: #2563eb;
              margin-bottom: 10px;
            }
            .grid {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 15px;
              margin-bottom: 20px;
            }
            .detail {
              padding: 10px;
              background-color: #f8fafc;
              border-radius: 4px;
            }
            .detail-label {
              font-weight: bold;
              color: #64748b;
            }
            .amenities-list {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 10px;
              list-style-type: none;
              padding: 0;
            }
            .amenities-list li {
              padding: 5px 0;
            }
            .footer {
              margin-top: 30px;
              text-align: center;
              font-size: 12px;
              color: #64748b;
              border-top: 1px solid #e2e8f0;
              padding-top: 20px;
            }
            @media print {
              body {
                -webkit-print-color-adjust: exact;
                print-color-adjust: exact;
              }
              .warning-box {
                border-left-color: #856404 !important;
              }
            }
            ${extraStyles}
          </style>
        </head>
        <body>
          <div class="header">
            <h1>${property.title} on RentSmart.ie</h1>
            <p>${capitalizeWords(property.location)}</p>
          </div>

          <img src="${property.images[0]}" alt="${property.title}" class="property-image" />

          <div class="section">
            <div class="section-title">Key Details</div>
            <div class="grid">
              <div class="detail">
                <span class="detail-label">Price:</span> ${formatPrice(property.price)}
              </div>
              <div class="detail">
                <span class="detail-label">Type:</span> ${property.type}
              </div>
              <div class="detail">
                <span class="detail-label">Bedrooms:</span> ${property.bedrooms}
              </div>
              <div class="detail">
                <span class="detail-label">Bathrooms:</span> ${property.bathrooms}
              </div>
              ${
                property.berRating
                  ? `
              <div class="detail">
                <span class="detail-label">BER Rating:</span> ${property.berRating}
              </div>`
                  : ''
              }
              <div class="detail">
                <span class="detail-label">Available From:</span> ${formatAvailableDate(property.availableFrom)}
              </div>
            </div>
          </div>

          <div class="section">
            <div class="section-title">Description</div>
            <p>${property.description}</p>
          </div>

          <div class="section">
            <div class="section-title">Amenities</div>
            <ul class="amenities-list">
              ${property.amenities.map((amenity) => `<li>✓ ${amenity}</li>`).join('')}
            </ul>
          </div>

          ${contactSection}

          <div class="footer">
            <p>Generated from RentSmart.ie on ${new Date().toLocaleDateString()}</p>
            <p>For more information, visit: ${window.location.href}</p>
          </div>
        </body>
      </html>
    `;

    printWindow.document.write(printContent);
    printWindow.document.close();

    printWindow.onload = () => {
      printWindow.print();
    };
  };

  return (
    <button
      onClick={handlePrint}
      className="group relative inline-flex items-center px-6 py-3
             bg-gradient-to-b from-white to-gray-50
             border border-gray-200 hover:border-gray-300
             rounded-xl shadow-sm hover:shadow
             text-gray-700 hover:text-gray-900
             text-sm font-semibold
             transition-all duration-200
             focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
    >
      <PrinterIcon
        className="h-5 w-5 mr-2 text-blue-500 group-hover:text-blue-600"
        aria-hidden="true"
      />
      <span className="bg-gradient-to-r from-gray-800 to-gray-600 bg-clip-text group-hover:text-transparent transition-all duration-200">
        Print Details
      </span>
    </button>
  );
};

export default PrintButton;
